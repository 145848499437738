import { errorFormatter } from ".";

export const errNotificationHelper = (err: any) => {
  return {
    title: errorFormatter(err).message,
    description: (
      <div className="space-y-4">
        {errorFormatter(err)?.errors?.map((e, i) => (
          <span key={i} className="capitalize">
            {e.path} : {e.message}
          </span>
        ))}
      </div>
    ),
  };
};
