import { TAddOn } from "../types";
import { TTrainingSessionBookingStatus } from "../types/addOns/trainingSession";
import { AciveCompanySubscription } from "../types/company/active-company-subscription";

export const INVOICE_QUOTATION_BOTTOM_INFO =
  "This quote does not include integrations or development-related customizations for HCMATRIX, which is a subscription-based cloud SaaS. This quote does not include travel arrangements (if the need be) - Invoicing: The next payment date will be 12 months following the subscription.The quote includes VAT. - Annual Renewal includes the following benefits: - Technical Support & Bug Fixes: Standard technical support, system troubleshooting, and so forth. - System Upgrades: Receive full access to all new system enhancements, UI/UX improvements, and security updates atleast twice a year. - Configuration Support: Virtual assistance with any necessary configuration modifications.";
export const mockActiveCompanySubscription: AciveCompanySubscription = {
  totalLicensedUsedCount: 0,
  totalUnlicensedUsedCount: 0,
  totalLicensesUsedCount: 0,
  totalLicensesPurchasedCount: 300,
  id: 4,
  companyId: 1,
  type: "plan",
  startDate: "2024-10-01T03:30:47.000Z",
  endDate: "2024-11-01T22:59:59.000Z",
  billingCycle: "monthly",
  currency: "NGN",
  autoRenewal: true,
  status: "active",
  licensedEmployeeCount: 200,
  unlicensedEmployeeCount: 100,
  vatId: 1,
  discountId: null,
  planId: 3,
  pricingId: 75,
  createdAt: "2024-10-01T03:29:53.000Z",
  updatedAt: "2024-10-01T03:30:48.000Z",
  plan: {
    id: 3,
    name: "Basic Plan",
    label: "basic-plan",
    isFree: false,
    description: null,
    createdAt: "2024-09-25T22:07:45.000Z",
    updatedAt: "2024-09-25T22:07:45.000Z",
    modules: [
      {
        id: 1,
        name: "Employee Management",
        label: "employee-management",
        iconUrl: null,
        description: "(N.B: You cannot uncheck this module)",
        createdAt: "2024-09-24T22:25:54.000Z",
        updatedAt: "2024-09-24T22:25:54.000Z",
        subscription_plan_module: {
          createdAt: "2024-09-25T22:07:47.000Z",
          updatedAt: "2024-09-25T22:07:47.000Z",
          moduleId: 1,
          planId: 3,
        },
        features: [
          {
            id: 1,
            name: "Onboarding",
            label: "onboarding",
            moduleId: 1,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 2,
            name: "Position Change Requisition",
            label: "position-change-requisition",
            moduleId: 1,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 3,
            name: "Promotion Requisition",
            label: "promotion-requisition",
            moduleId: 1,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 4,
            name: "Transfer Requisition",
            label: "transfer-requisition",
            moduleId: 1,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 5,
            name: "Handover Form",
            label: "handover-form",
            moduleId: 1,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 6,
            name: "Documents",
            label: "documents",
            moduleId: 1,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 7,
            name: "Basic Workflow",
            label: "basic-workflow",
            moduleId: 1,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 8,
            name: "Health Access",
            label: "health-access",
            moduleId: 1,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 9,
            name: "Company Organogram",
            label: "company-organogram",
            moduleId: 1,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 13,
            name: "Job Requisition",
            label: "job-requisition",
            moduleId: 1,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 14,
            name: "Reimbursement Requisition",
            label: "reimbursement-requisition",
            moduleId: 1,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 17,
            name: "Advanced Workflow",
            label: "advanced-workflow",
            moduleId: 1,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 48,
            name: "Requisition Management",
            label: "requisition-management",
            moduleId: 1,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 49,
            name: "Monetary Requisition",
            label: "monetary-requisition",
            moduleId: 1,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 50,
            name: "Travel Requisition",
            label: "travel-requisition",
            moduleId: 1,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
        ],
      },
      {
        id: 2,
        name: "Core HR",
        label: "core-hr",
        iconUrl: null,
        description: null,
        createdAt: "2024-09-24T22:25:54.000Z",
        updatedAt: "2024-09-24T22:25:54.000Z",
        subscription_plan_module: {
          createdAt: "2024-09-25T22:07:47.000Z",
          updatedAt: "2024-09-25T22:07:47.000Z",
          moduleId: 2,
          planId: 3,
        },
        features: [
          {
            id: 10,
            name: "Asset Management",
            label: "asset-management",
            moduleId: 2,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 11,
            name: "Vehicle Booking",
            label: "vehicle-booking",
            moduleId: 2,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 12,
            name: "Conference Room Booking",
            label: "conference-room-booking",
            moduleId: 2,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 15,
            name: "Disciplinary (Coming Soon)",
            label: "disciplinary",
            moduleId: 2,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 16,
            name: "Task Management",
            label: "task-management",
            moduleId: 2,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 18,
            name: "Leave",
            label: "leave",
            moduleId: 2,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 46,
            name: "Manage Mutiple Company",
            label: "manage-multiple-company",
            moduleId: 2,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 47,
            name: "Company Management",
            label: "company-management",
            moduleId: 2,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
        ],
      },
      {
        id: 3,
        name: "Payroll",
        label: "payroll",
        iconUrl: null,
        description: null,
        createdAt: "2024-09-24T22:25:54.000Z",
        updatedAt: "2024-09-24T22:25:54.000Z",
        subscription_plan_module: {
          createdAt: "2024-09-25T22:07:47.000Z",
          updatedAt: "2024-09-25T22:07:47.000Z",
          moduleId: 3,
          planId: 3,
        },
        features: [
          {
            id: 19,
            name: "Payroll Analytics",
            label: "payroll-analytics",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 20,
            name: "Tax Configuration",
            label: "tax-configuration",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 21,
            name: "Allowances and Deductions",
            label: "allowances-and-deductions",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 22,
            name: "Step Pay Payroll",
            label: "step-pay-payroll",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 23,
            name: "Direct Salary Payroll",
            label: "direct-salary-payroll",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 24,
            name: "Project Payroll",
            label: "project-payroll",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 25,
            name: "Wages Payroll",
            label: "wages-payroll",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 26,
            name: "Payroll Disbursement",
            label: "payroll-disbursement",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 27,
            name: "Tax Authorities",
            label: "tax-authorities",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 28,
            name: "Pension Authorities",
            label: "pension-authorities",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 29,
            name: "ITF Authorities",
            label: "itf-authorities",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 30,
            name: "NSITF Authorities",
            label: "nsitf-authorities",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 31,
            name: "Payslip",
            label: "payslip",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 32,
            name: "Payroll Approval",
            label: "payroll-approval",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 33,
            name: "Roll Back Payroll",
            label: "rollback-payroll",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 34,
            name: "Exchange Rates",
            label: "exchange-rates",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 35,
            name: "Loan",
            label: "loan",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 51,
            name: "Payroll Management",
            label: "payroll-management",
            moduleId: 3,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
        ],
      },
      {
        id: 4,
        name: "Time and Attendance",
        label: "time-and-attendance",
        iconUrl: null,
        description: null,
        createdAt: "2024-09-24T22:25:54.000Z",
        updatedAt: "2024-09-24T22:25:54.000Z",
        subscription_plan_module: {
          createdAt: "2024-09-25T22:07:47.000Z",
          updatedAt: "2024-09-25T22:07:47.000Z",
          moduleId: 4,
          planId: 3,
        },
        features: [
          {
            id: 36,
            name: "Clock-in, Clock-out and Break",
            label: "clock-in-clock-out-and-break",
            moduleId: 4,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 37,
            name: "Time and Attendance Settings",
            label: "time-and-attendance-settings",
            moduleId: 4,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 38,
            name: "IP and Geo-Restriction",
            label: "ip-and-geo-restriction",
            moduleId: 4,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 39,
            name: "Timesheet Management",
            label: "timesheet-management",
            moduleId: 4,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 40,
            name: "Soft Clock in (TAMs Device)",
            label: "soft-clock-in",
            moduleId: 4,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 41,
            name: "Overtime Calculation",
            label: "overtime-calculation",
            moduleId: 4,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 42,
            name: "Time-off Request",
            label: "time-off-request",
            moduleId: 4,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 43,
            name: "Shift Scheduling",
            label: "shift-scheduling",
            moduleId: 4,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 44,
            name: "Geo-Tracking/Geo-Fencing",
            label: "geo-tracking",
            moduleId: 4,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
          {
            id: 45,
            name: "Shift Rotation",
            label: "shift-rotation",
            moduleId: 4,
            createdAt: "2024-09-24T22:29:52.000Z",
            updatedAt: "2024-09-24T22:29:52.000Z",
          },
        ],
      },
    ],
  },
  addons: [
    {
      id: 19,
      name: "Enterprise Support",
      label: "enterprise-support",
      type: "support",
      description:
        "Velit eum provident voluptates. Magnam ipsam quaerat. Aut aut laudantium et repellendus perspiciatis. Autem ab aut sapiente totam omnis.",
      trainingHours: null,
      createdAt: "2024-09-25T00:37:10.000Z",
      updatedAt: "2024-09-25T00:37:10.000Z",
      CompanySubscriptionAddon: {
        companySubscriptionId: 4,
        addonId: 19,
        pricingId: 15,
        createdAt: "2024-10-01T03:29:54.000Z",
        updatedAt: "2024-10-01T03:29:54.000Z",
      },
    },
    {
      id: 22,
      name: "Advanced",
      label: "advanced",
      type: "training",
      description:
        "Quasi sed et quibusdam libero dolores rerum suscipit fugit consequuntur. Rerum voluptatem temporibus. Ullam dolor unde optio consequuntur possimus explicabo praesentium illum. Quisquam ullam voluptates modi. Sapiente qui nihil neque. Nulla quia tempora et ea illum excepturi voluptate in.",
      trainingHours: 65,
      createdAt: "2024-09-25T00:37:10.000Z",
      updatedAt: "2024-09-25T00:37:10.000Z",
      CompanySubscriptionAddon: {
        companySubscriptionId: 4,
        addonId: 22,
        pricingId: 18,
        createdAt: "2024-10-01T03:29:54.000Z",
        updatedAt: "2024-10-01T03:29:54.000Z",
      },
    },
  ],
  billingHistory: [
    {
      id: 4,
      companyId: 1,
      companySubscriptionId: 4,
      billingDate: "2024-10-01T03:29:53.000Z",
      totalSubscriptionAmount: "700000.00",
      totalAddonAmount: "50000.00",
      totalDiscountAmount: "0.00",
      totalVatAmount: "56250.00",
      totalAmount: "806250.00",
      amountPaid: "274583.33",
      currency: "NGN",
      status: "paid",
      name: "Snapnet Solutions",
      phone: "+234-9038493243",
      addressId: 99,
      paymentReference: "tejp94ju8l",
      createdAt: "2024-10-01T03:29:54.000Z",
      updatedAt: "2024-10-01T03:30:48.000Z",
    },
  ],
};
export const SUBSCRIPTION_ADD_ONS: TAddOn[] = [
  {
    name: "supportCase",
    title: "Support Case",
    options: [
      { label: "Support Case 1", value: "Support Case 1" },
      { label: "Support Case 2", value: "Support Case 2" },
    ],
  },
  {
    name: "extraStorage",
    title: "Extra Storage",
    options: [
      { label: "Support Case 1", value: "Support Case 1" },
      { label: "Support Case 2", value: "Support Case 2" },
    ],
  },
  {
    name: "trainingSession",
    title: "Training Session",
    options: [
      { label: "Support Case 1", value: "Support Case 1" },
      { label: "Support Case 2", value: "Support Case 2" },
    ],
  },
];

export const PRICE_TYPE_CURRENCY = {
  NGN: "₦",
  USD: "$",
};

export const TRAINING_BOOKING_STATUSES: TTrainingSessionBookingStatus[] = [
  "accepted",
  "completed",
  "pending",
  "rejected",
];
